import React, { Fragment, useEffect, useState } from 'react'
import { isSafari } from 'react-device-detect'
import {
  Form,
  Button,
  Select,
  Input,
  Tooltip,
  Row,
  Col,
  Tabs,
  List,
  Avatar,
  message,
  Modal,
  Spin,
  Progress
} from 'antd'
import {
  SoundOutlined,
  PlusCircleOutlined,
  MutedOutlined,
  LeftOutlined
} from '@ant-design/icons'
import './DiscussionCreateMachine.scss'
import { useTranslation } from 'react-i18next'
import { ImageSelect } from '../../../../components/Form'
import FileUploadComponent from '../components/UploadFiles/UploadFiles'
import './DiscussionCreateMachine.scss'
import actions from '../../../../store/actions'
import { useDispatch } from 'react-redux'
import ReactPlayer from 'react-player'
import {
  AudioIcon,
  AudioModalTitleIcon
} from '../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/Icons'
import FooterActions from '../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/FooterActions'
import Audio from '../../../Courses/Create/nestedComponents/PageBuilder/Components/Audio'
import _ from 'lodash'
import { useRef } from 'react'
import {
  CreateAudio,
  AddDetailAudio
} from '../../../Courses/Create/nestedComponents/PageBuilder/Components/Audio/internal/Create'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import debounce from 'lodash/debounce'
import { useCallback } from 'react'
import { deactiveSoundIcon } from '../../../Courses/Create/nestedComponents/PageBuilder/Components/Audio/images'
import Dropzone from 'react-dropzone'
import { api } from '../../../../services'

const { Option } = Select
const { TabPane } = Tabs
const DiscussionCreateMachine = ({
  form,
  open,
  onFinish,
  close,
  roomName,
  description,
  selectedCover,
  library,
  upload,
  clearUploadList
}) => {
  const audioRef = useRef(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [step, setStep] = useState(0)
  const [tabIndex, setTabIndex] = useState('1')
  const [sampleAudioUrl, setSampleAudioUrl] = useState({ link: '', type: '' })
  const [isPaused, setIsPaused] = useState(true)
  const [voiceList, setVoiceList] = useState([{ name: '', voice_id: '' }])
  const [musicList, setMusicList] = useState([{ filename: '', id: '' }])
  const [allEpisode, setEpisode] = useState([
    {
      content: [],
      speakers: [],
      cover: { id: '', link: '', file_size: '', filename: '' },
      cover_id: '',
      title: '',
      id: '',
      link: '',
      filename: '',
      description: '',
      planeTextDescription: '',
      music_id: ''
    }
  ])
  const [formValues, setFormValues] = useState({
    cover: { id: '', link: '', file_size: '', filename: '' },
    cover_id: '',
    title: '',
    description: '',
    speakers: null,
    episodes: null,
    length: '',
    host: '',
    tone: '',
    music_id: '',
    files: [],
    guests: ['kismat']
  })
  const [attachedData, setAttachedData] = useState({
    filenames: [],
    attachmentIds: []
  })
  const [speakerList, setSpeakerList] = useState([])
  const [isEdit, setIsEdit] = useState({
    status: false,
    context: '',
    index: null,
    contentIndex: null
  })
  const [isSaving, setIsSaving] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [openMusicModal, setMusicModal] = useState({
    open: false,
    isUploading: false,
    index: '',
    id: '',
    link: ''
  })
  const [openAudioModal, setOpenAudioModal] = useState({
    open: false,
    id: '',
    index: '',
    speakerIndex: '',
    src: ''
  })
  const [selectedAudio, setSelectedAudio] = useState({
    id: '',
    audio_url: '',
    name: ''
  })
  const [isVoiceLoading, setIsVoiceLoading] = useState(false)
  const {
    getFieldDecorator,
    validateFields,
    getFieldsValue,
    setFieldsValue
  } = form
  useEffect(() => {
    getAllVoice()
    getAllMusic()
  }, [])
  useEffect(() => {
    setFieldsValue({
      files: attachedData.attachmentIds
    })
    // setFormValues({ ...formValues, files: attachedData.attachmentIds })
  }, [attachedData.attachmentIds])

  useEffect(() => {
    if (audioRef.current && sampleAudioUrl.link) {
      audioRef.current.pause()
      if (!isPaused && sampleAudioUrl.type === 'music') {
        audioRef.current.load()
        audioRef.current.play().catch(error => {
          console.error('Audio playback failed', error)
        })
      } else if (sampleAudioUrl.type === 'voice') {
        setIsPaused(true)
        audioRef.current.load()
        audioRef.current.play().catch(error => {
          console.error('Audio playback failed', error)
        })
      }
      const handleEnded = () => {
        audioRef.current.currentTime = 0
        if (sampleAudioUrl.type === 'music') {
          setIsPaused(true)
        } else {
          setSampleAudioUrl({ ...sampleAudioUrl, link: '' })
        }
      }
      audioRef.current.addEventListener('ended', handleEnded)
      return () => {
        audioRef.current.removeEventListener('ended', handleEnded)
      }
    }
  }, [sampleAudioUrl, isPaused])
  const getAllVoice = async () => {
    const res = await dispatch(actions.podcast.getAllVoices())
    if (res.data && res.status <= 300 && res.status >= 200) {
      if (res.data.voices) {
        setVoiceList(res.data.voices)
      }
    }
  }
  const getAllMusic = async () => {
    const res = await dispatch(actions.podcast.getAllMusic())
    if (res.data && res.status <= 300 && res.status >= 200) {
      if (res.data.rows.length > 0) {
        setMusicList(res.data.rows)
      } else {
        message.error('Music list is currently empty!')
      }
    }
  }
  const previous = () => {
    setFieldsValue({
      cover_id: formValues.cover_id,
      title: formValues.title,
      description: formValues.description,
      episodes: formValues.episodes,
      speakers: formValues.speakers,
      guests: formValues.guests,
      host: formValues.host,
      length: formValues.length,
      tone: formValues.tone,
      files: attachedData.attachmentIds
    })
    let speakerList = []
    formValues.guests.forEach(name => {
      speakerList.push({ speaker_name: name })
    })
    setSpeakerList(speakerList)
    setStep(0)
    setEpisode(prevEpisodes => {
      const newEpisodes = prevEpisodes.map(episode => ({
        ...episode,
        id: '',
        link: '',
        filename: ''
      }))
      return newEpisodes
    })
  }
  const checkContentStatus = async id => {
    try {
      const res = await dispatch(actions.podcast.checkPodcastStatus(id))
      if (res.data && res.status >= 200 && res.status <= 300) {
        if (res.data.link) {
          try {
            const response = await fetch(res.data.link)
            if (!response.ok) {
              throw new Error('Could not fetch content!')
            }
            let episodeList = await response.json()
            let tempEpishodes = []
            episodeList.forEach(item => {
              tempEpishodes.push({
                content: item.content,
                speakers: item.speakers,
                title: item.title,
                description: '',
                planeTextDescription: '',
                id: '',
                link: '',
                filename: '',
                cover: {
                  id: selectedCover.id,
                  link: selectedCover.link,
                  file_size: selectedCover.file_size,
                  filename: selectedCover.filename
                },
                cover_id: selectedCover.id
              })
            })
            setEpisode(tempEpishodes)
            setStep(1)
          } catch (error) {
            message.error(error.message)
          } finally {
            setIsGenerating(false)
          }
        } else {
          setTimeout(() => {
            checkContentStatus(id)
          }, 7000)
        }
      } else {
        throw new Error('Failed to generate content!')
      }
    } catch (error) {
      message.error(error.message)
      setIsGenerating(false)
    }
  }
  const generateContent = async () => {
    validateFields()
      .then(async values => {
        let data = {
          title: roomName,
          description: description || 'test',
          speakers: values.guests,
          files: attachedData.attachmentIds,
          episodes: parseInt(values.episodes),
          length: values.length,
          tone: values.tone
        }
        setIsGenerating(true)
        try {
          let res = await dispatch(actions.podcast.generateConversation(data))
          if (res.data && res.status <= 300 && res.status >= 200) {
            checkContentStatus(res.data.id)
            setFormValues({
              ...formValues,
              title: roomName,
              description: description,
              episodes: values.episodes || 1,
              speakers: values.speakers,
              guests: values.guests,
              host: values.host,
              length: values.length,
              tone: values.tone
            })
          } else {
            message.error('Failed to generate content!')
            setIsGenerating(false)
          }
        } catch (error) {
          setIsGenerating(false)
          message.error('Failed to generate podcast content!')
        }
      })
      .catch(err => {
        // for (let key in err) {
        //   if (err[key].errors && err[key].errors.length > 0) {
        //     message.error(err[key].errors[0].message)
        //     return
        //   }
        // }
        message.error('Please fill all the required fields!')
      })
  }

  const setCoverImage = (cover, index) => {
    setFieldsValue({ [`cover_id${index}`]: cover.id })
    setEpisode(prevEpisodes => {
      const newEpisodes = [...prevEpisodes]
      newEpisodes[index] = {
        ...newEpisodes[index],
        cover: cover,
        cover_id: cover.id
      }
      return newEpisodes
    })
  }
  const changeEpisodeTitle = useCallback(
    debounce((title, index) => {
      setEpisode(prevEpisodes => {
        const newEpisodes = [...prevEpisodes]
        newEpisodes[index] = {
          ...newEpisodes[index],
          title: title
        }
        return newEpisodes
      })
    }, 500),
    []
  )
  const setEditorDescription = useCallback(
    debounce((editorText, planeText, index) => {
      setEpisode(prevEpisodes => {
        const newEpisodes = [...prevEpisodes]
        newEpisodes[index] = {
          ...newEpisodes[index],
          description: editorText,
          planeTextDescription: planeText
        }
        return newEpisodes
      })
    }, 500),
    []
  )
  const changeHostName = e => {
    let name = e.target.value
    setSpeakerList(prevSpeakerList => {
      const updatedSpeakerList = [...prevSpeakerList]
      if (updatedSpeakerList.length > 0) {
        updatedSpeakerList[0] = {
          ...updatedSpeakerList[0],
          speaker_name: name
        }
      }
      return updatedSpeakerList
    })
    setFieldsValue({ 'guests[0]': name })
  }
  const handleChangeSpeaker = number => {
    let hostname = getFieldsValue().host
    let speakerList = []
    for (let i = 1; i <= number; i++) {
      if (i === 1) {
        speakerList.push({ speaker_name: hostname })
      } else {
        speakerList.push({ speaker_name: '' })
      }
    }
    setSpeakerList(speakerList)
  }
  const episodeChanage = index => {
    setTabIndex(index)
    setIsPaused(true)
  }
  const listenSample = (speaker, index, speakerIndex) => {
    let voice = { preview_url: '' }
    voice = voiceList.find(
      el =>
        el.voice_id ===
        getFieldsValue()[`${speaker}_voice_e${index}_s${speakerIndex}`]
    )
    if (voice.preview_url) {
      setSampleAudioUrl({ link: voice.preview_url, type: 'voice' })
    } else {
      message.warn('There is audio sample for this voice!')
    }
  }
  const listenMusic = (status, index) => {
    let music_id = getFieldsValue()[`music_id${index}`]
    let music = musicList.find(el => el.id === music_id)
    console.log('test', music)
    if (!music) {
      message.error('Please select music!')
      return
    } else if (music.link && music.link !== sampleAudioUrl) {
      setSampleAudioUrl({ link: music.link, type: 'music' })
    }
    setIsPaused(status)
  }
  const updateContext = (index, contentIndex) => {
    setEpisode(prevEpisodes => {
      const newEpisodes = [...prevEpisodes]
      newEpisodes[index] = {
        ...newEpisodes[index],
        id: '',
        link: '',
        filename: '',
        content: prevEpisodes[index].content.map((item, i) =>
          i === contentIndex ? { ...item, context: isEdit.context } : item
        )
      }
      return newEpisodes
    })
    setIsEdit({ index: -1, contentIndex: -1, status: false, contents: '' })
  }
  const handleInputChange = e => {
    const value = e.target.value
    setIsEdit({ ...isEdit, context: value })
  }
  const generateAudio = async index => {
    if (!allEpisode[index].planeTextDescription) {
      setFieldsValue({ [`description${index}`]: '' })
      validateFields()
      message.error('Please fill all the required fields!')
      return
    }
    setIsPaused(true)
    let voices = []
    let speakers = []
    allEpisode.forEach((el, i) => {
      if (i === index) {
        el.speakers.forEach((speaker, speakerIndex) => {
          speakers.push(speaker)
          voices.push({
            [speaker]: getFieldsValue()[
              `${speaker}_voice_e${index}_s${speakerIndex}`
            ]
          })
        })
      }
    })
    const objectVoices = voices.reduce((acc, obj) => {
      return { ...acc, ...obj }
    }, {})
    let payload = {
      title: allEpisode[index].title,
      speakers: speakers,
      voices: objectVoices,
      content: allEpisode[index].content,
      ...(getFieldsValue()[`music_id${index}`] && {
        music_id: getFieldsValue()[`music_id${index}`]
      })
    }
    setIsGenerating(true)
    setEpisode(prevEpisodes => {
      const newEpisodes = [...prevEpisodes]
      newEpisodes[index] = {
        ...newEpisodes[index],
        id: '',
        link: '',
        filename: ''
      }
      return newEpisodes
    })
    const res = await dispatch(actions.podcast.generatePodcastAudio(payload))
    if (res.data && res.status <= 300 && res.status >= 200) {
      checkPodcastStatus(res.data.id, index)
    } else {
      message.error('Failed to generate audio!')
      setIsGenerating(false)
    }
  }
  const checkPodcastStatus = async (id, index) => {
    const res = await dispatch(actions.podcast.checkPodcastStatus(id))
    if (res.data && res.status <= 300 && res.status >= 200) {
      if (res.data.link) {
        setEpisode(prevEpisodes => {
          const newEpisodes = [...prevEpisodes]
          newEpisodes[index] = {
            ...newEpisodes[index],
            id: res.data.id,
            link: res.data.link,
            filename: res.data.filename
          }
          return newEpisodes
        })

        setIsGenerating(false)
      } else {
        setTimeout(() => {
          checkPodcastStatus(id, index)
        }, 10000)
      }
    } else {
      setIsGenerating(false)
      message.error('Failed to generate audio!')
    }
  }
  const createPodcastEpisodes = async () => {
    setIsSaving(true)
    onFinish(allEpisode)
    setTimeout(() => {
      setIsSaving(false)
    }, 300)
  }
  const createMusic = () => {}
  const createVoice = async payload => {
    setIsVoiceLoading(true)
    const res = await dispatch(actions.podcast.createVoice(payload))
    if (res.data && res.status <= 300 && res.status >= 200) {
      let tempVoice = [...voiceList]
      tempVoice.push(res.data)
      setVoiceList(tempVoice)
      setIsVoiceLoading(false)
      setOpenAudioModal({ ...openAudioModal, open: false })
      setFieldsValue({
        [openAudioModal.id +
        '_voice' +
        '_e' +
        openAudioModal.index +
        '_s' +
        openAudioModal.speakerIndex]: res.data.voice_id
      })
      clearUploadList()
    } else {
      clearUploadList()
      setIsVoiceLoading(false)
      message.error('Failed to create voice!')
    }
  }
  const onShowCreateModal = () => {
    setCreateModal(true)
    setOpenAudioModal({ ...openAudioModal, status: false })
  }
  const onShowDetailModal = src => {
    setCreateModal(false)
    setDetailModal(true)
    setOpenAudioModal({ ...openAudioModal, status: false, src: src })
  }
  const getSelectedFile = files => {
    let file = files[0]
    let filename = file.filename
      .replace(/\.mp3$/gi, '')
      .replace(/[-_]/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase())
    setSelectedAudio({ id: file.id, audio_url: file.link, name: filename })
  }

  const handleInsertData = ({ rows: privateData }, publicMedia, orgMedia) => {
    let tempData = privateData
    if (publicMedia) {
      const { rows: publicData } = publicMedia
      tempData = [...privateData, ...publicData]
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      tempData = [...privateData, ...orgData]
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))
    let filename = selectedItem.filename
      .replace(/\.mp3$/gi, '')
      .replace(/[-_]/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase())
    setSelectedAudio({
      id: selectedItem.id,
      audio_url: selectedItem.link,
      name: filename
    })
  }
  const handleFileUpload = files => {
    let file = files[0]
    if (!file) {
      return
    }
    setOpenAudioModal({ ...openAudioModal, status: true })
    setDetailModal(false)
    let filename = file.filename
      .replace(/\.mp3$/gi, '')
      .replace(/[-_]/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase())
    createVoice({ id: file.id, audio_url: file.link, name: filename })
  }
  const selectMusic = id => {
    let music = musicList.find(el => el.id === id)
    setSampleAudioUrl({ link: music ? music.link : '', type: 'music' })
    setIsPaused(false)
  }
  const onDropFile = async dropped => {
    let file = dropped[0]
    clearUploadList()
    if (!file) {
      return
    }
    const maxSize = 100 * 1024 * 1024
    if (file.size > maxSize) {
      message.error('File size must be lower then 100 MB!')
      return
    }
    if (file.type !== 'audio/mpeg') {
      message.error('Invalid file format (only accepts .mp3)!')
      return
    }
    setMusicModal({ ...openMusicModal, isUploading: true })
    let res = await upload(file)
    let formData = {
      id: res.id,
      filename: res.filename
        .replace(/\.mp3$/gi, '')
        .replace(/[-_]/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase()),
      keywords: 'music',
      description: 'music',
      title: res.title,
      category: 'music'
    }
    await api.library.updateMedia(formData.id, formData)
    let tempMusic = [...musicList]
    tempMusic.push(formData)
    setMusicList(tempMusic)
    setFieldsValue({
      ['music_id' + openMusicModal.index]: formData.id
    })
    // play the music
    setSampleAudioUrl({ link: res.link, type: 'music' })
    setIsPaused(false)

    clearUploadList()
    setMusicModal({
      ...openMusicModal,
      open: false,
      isUploading: false,
      id: res.id,
      link: res.link
    })
  }
  return (
    <Modal
      title={'Generate Podcast Audio'}
      visible={open}
      onCancel={close}
      maskClosable={false}
      footer={[
        step === 0 && (
          <Fragment>
            <Tooltip
              placement="left"
              title={
                'Generates the content relative to the user uploaded files with the given input parameters.'
              }
              arrow
            >
              <span>
                <Button
                  type="primary"
                  className="rounded"
                  loading={isGenerating}
                  onClick={generateContent}
                  disabled={isUploading}
                >
                  Generate to text
                </Button>
              </span>
            </Tooltip>
          </Fragment>
        ),
        step === 1 && (
          <Fragment>
            <Tooltip
              placement="left"
              title={'Go back to content generation state.'}
              arrow
            >
              <span>
                <Button
                  type="primary"
                  className="rounded"
                  onClick={() => {
                    previous()
                  }}
                  disabled={isGenerating}
                >
                  <LeftOutlined /> Go Back
                </Button>
              </span>
            </Tooltip>
            &nbsp;
            <Tooltip
              placement="left"
              title={
                allEpisode.every(
                  episode => episode.link && episode.link.trim() !== ''
                )
                  ? 'Create an AI-powered podcast episode(s) with generated audio.'
                  : 'Please generate voice for all podcast epishode(s)'
              }
              arrow
            >
              <span>
                <Button
                  type="primary"
                  className="rounded"
                  loading={isSaving}
                  disabled={
                    !allEpisode.every(
                      episode => episode.link && episode.link.trim() !== ''
                    )
                  }
                  onClick={createPodcastEpisodes}
                >
                  Create Podcast Episode
                </Button>
              </span>
            </Tooltip>
          </Fragment>
        )
      ]}
      centered
      width="85vw"
      className="full-width-modal"
    >
      <audio ref={audioRef} src={sampleAudioUrl.link} autoPlay />
      <Modal
        className="custom-ant-modal insert-audio-detail"
        visible={detailModal}
        onCancel={() => {
          setDetailModal(false)
        }}
        width={'40%'}
        footer={false}
      >
        <AddDetailAudio
          handleFileUpload={handleFileUpload}
          getSelectedFile={getSelectedFile}
          src={openAudioModal.src}
          isFromCreateAudio
          onHideDetailModal={() => {
            setDetailModal(false)
          }}
          t={t}
        />
      </Modal>
      <Modal
        className="custom-ant-modal"
        title={<AudioModalTitleIcon t={t} isShowCreateAudioModal={true} />}
        visible={createModal}
        onCancel={() => {
          setCreateModal(false)
          setOpenAudioModal({
            id: '',
            open: true,
            src: '',
            index: '',
            speakerIndex: ''
          })
        }}
        footer={false}
        width={'88%'}
      >
        <CreateAudio onShowDetailModal={onShowDetailModal} t={t} />
      </Modal>
      <Modal
        title={<AudioIcon t={t} />}
        className="custom-ant-modal"
        onCancel={() => {
          setOpenAudioModal({
            id: '',
            open: false,
            src: '',
            index: '',
            speakerIndex: ''
          })
        }}
        visible={openAudioModal.open}
        width={'85%'}
        footer={
          <FooterActions
            t={t}
            isDisabled={!selectedAudio.audio_url}
            loading={isVoiceLoading}
            title={t('buttons:create')}
            onClickCancel={() => {
              setOpenAudioModal({
                id: '',
                open: false,
                src: '',
                index: '',
                speakerIndex: ''
              })
            }}
            onClickTitle={() => createVoice(selectedAudio)}
          />
        }
      >
        <Audio
          isPodcast
          isPageBuilder
          onShowCreateModal={onShowCreateModal}
          getSelectedFile={getSelectedFile}
          handleInsertData={handleInsertData}
          src={openAudioModal.src}
        />
      </Modal>
      <Modal
        className="custom-ant-modal"
        title={<AudioIcon t={t} />}
        onCancel={() => {
          setMusicModal({
            id: '',
            open: false,
            index: '',
            isUploading: false,
            link: ''
          })
        }}
        visible={openMusicModal.open}
        width={300}
        footer={
          <FooterActions
            t={t}
            isDisabled={!openMusicModal.link && !openMusicModal.isUploading}
            loading={openMusicModal.isUploading}
            title={t('buttons:create')}
            onClickCancel={() => {
              setMusicModal({
                id: '',
                open: false,
                index: '',
                isUploading: false,
                link: ''
              })
            }}
            onClickTitle={createMusic}
          />
        }
      >
        <div className="dropzone-body">
          <Dropzone
            accept={'.mp3'}
            multiple={false}
            onDrop={onDropFile}
            activeStyle={{
              height: '100%',
              width: '100%'
            }}
          >
            {() => (
              <div className={'music-dropzone'}>
                <div className="music-dropzone__text-wrapper">
                  {openMusicModal.isUploading ? (
                    <>
                      <Progress
                        type="circle"
                        percent={
                          library &&
                          library.uploadProgress[0] &&
                          library.uploadProgress[0].percent
                        }
                      />
                      <br />
                      <br />
                      <p>uploading in progress...</p>
                    </>
                  ) : (
                    <>
                      <img src={deactiveSoundIcon} alt="" />
                      <p>{t('media:drop_files')}</p>
                      <p>{t('media:or')}</p>
                      <Button>{'Import music form computer.'}</Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </Modal>

      <div className="discussion-machine-main">
        <div className="discussion-machine-main-body">
          <Form layout="vertical">
            {step === 0 && (
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={16} lg={14}>
                  <Form.Item
                    required={false}
                    colon={false}
                    className="no_bottom_margin"
                  >
                    {getFieldDecorator('cover_id', {
                      initialValue: selectedCover.id,
                      rules: [
                        {
                          required: true,
                          message: 'please upload podcast cover!'
                        }
                      ]
                    })(
                      <ImageSelect
                        isReadOnly
                        form={form}
                        label={'Cover Picture'}
                        coverInput={selectedCover || null}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label="Podcast Name"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('title', {
                      initialValue: roomName || '',
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the podcast name!'
                        }
                      ]
                    })(<Input readOnly />)}
                  </Form.Item>
                  <Form.Item
                    label="Podcast Description"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('description', {
                      initialValue: description || ''
                    })(
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onReady={editor => {
                          editor.isReadOnly = true
                        }}
                        config={{
                          toolbar: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'blockQuote',
                            'numberedList',
                            'bulletedList',
                            'undo',
                            'redo'
                          ],
                          removePlugins: [
                            'link',
                            'Image',
                            'ImageUpload',
                            'MediaEmbed',
                            'InsertImage',
                            'EasyImage',
                            'Video'
                          ]
                        }}
                      />
                    )}
                  </Form.Item>
                  <Row gutter={[8, 0]}>
                    <Col span={8}>
                      <Form.Item
                        label="Number of Speakers"
                        colon={false}
                        required={false}
                      >
                        {getFieldDecorator('speakers', {
                          initialValue: formValues.speakers || undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please select the number of speakers!'
                            }
                          ]
                        })(
                          <Select size="large" onChange={handleChangeSpeaker}>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Number of Episodes"
                        colon={false}
                        required={false}
                      >
                        {getFieldDecorator('episodes', {
                          initialValue: formValues.episodes || undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Please input number of episode!'
                            }
                          ]
                        })(
                          <Select size="large">
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Podcast Length"
                        colon={false}
                        required={false}
                      >
                        {getFieldDecorator('length', {
                          initialValue: formValues.length || '',
                          rules: [
                            {
                              required: true,
                              message: 'Please enter the length of the podcast!'
                            }
                          ]
                        })(
                          <Select size="large">
                            <Option value={'short'}>Short (3-4 min)</Option>
                            <Option value={'medium'}>Medium (4-6 min)</Option>
                            <Option value={'long'}>Long (5+ min)</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Name of the Host"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('host', {
                      initialValue: formValues.host || '',
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the host name!'
                        }
                      ]
                    })(<Input onChange={changeHostName} />)}
                  </Form.Item>
                  {speakerList.length >= 1 && (
                    <Form.Item
                      label="Name of guests"
                      colon={false}
                      required={false}
                    >
                      {speakerList.map((speaker, i) => (
                        <Form.Item
                          key={i}
                          colon={false}
                          required={false}
                          style={{ marginBottom: 0 }} // Removed '!important' since it's not valid in inline styles
                        >
                          {getFieldDecorator(`guests[${i}]`, {
                            initialValue: speaker.speaker_name || '',
                            rules: [
                              {
                                required: true,
                                message: 'Please enter the guest name!'
                              }
                            ]
                          })(
                            <Input
                              addonBefore={`No. ${i + 1} :`}
                              disabled={i === 0}
                            />
                          )}
                        </Form.Item>
                      ))}
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Choose tone of Speaker"
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('tone', {
                      initialValue: formValues.tone || undefined,
                      rules: [
                        {
                          required: true,
                          message: 'Please select the tone of the podcast!'
                        }
                      ]
                    })(
                      <Select size="large">
                        <Option value="serious">Serious</Option>
                        <Option value="light humor">Light Humor</Option>
                        <Option value="casual">Casual</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={10}>
                  <Form.Item
                    label={
                      <div style={{ display: 'flex', textTransform: 'none' }}>
                        Upload Files (word files, pdf)
                        <div
                          style={{
                            marginLeft: 10,
                            background: 'red',
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            height: 20,
                            width: 20
                          }}
                        >
                          {attachedData.filenames.length}
                        </div>
                      </div>
                    }
                    colon={false}
                    required={false}
                    className="no_bottom_margin"
                  >
                    {getFieldDecorator('files', {
                      initialValue: attachedData.filenames || undefined,
                      rules: [
                        {
                          required: true,
                          message: 'Please add file(s)!'
                        }
                      ]
                    })(
                      <FileUploadComponent
                        setIsLoading={setIsUploading}
                        ids={attachedData.attachmentIds}
                        data={attachedData}
                        setData={setAttachedData}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {step === 1 && (
              <Fragment>
                <Tabs
                  defaultActiveKey="1"
                  onChange={episodeChanage}
                  activeKey={tabIndex}
                >
                  {allEpisode.length !== 0 &&
                    allEpisode.map((episode, i) => {
                      return (
                        <TabPane
                          tab={`Episode ${i + 1}`}
                          key={JSON.stringify(i + 1)}
                          disabled={
                            isGenerating && tabIndex !== JSON.stringify(i + 1)
                          }
                        >
                          <Row gutter={[16, 8]}>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                required={false}
                                colon={false}
                                className="no_bottom_margin"
                              >
                                {getFieldDecorator('cover_id' + i, {
                                  initialValue: episode.cover.id || '',
                                  rules: [
                                    {
                                      required: true,
                                      message: 'please upload podcast cover!'
                                    }
                                  ]
                                })(
                                  <ImageSelect
                                    isReadOnly={isGenerating}
                                    tooltip={t('v4:info_episode')}
                                    form={form}
                                    label={'Cover Picture'}
                                    coverInput={
                                      episode.cover.id ? episode.cover : null
                                    }
                                    onCoverChange={cover =>
                                      setCoverImage(cover, i)
                                    }
                                  />
                                )}
                              </Form.Item>
                              <Form.Item
                                label="Podcast Episode Title"
                                colon={false}
                                required={false}
                              >
                                {getFieldDecorator('title' + i, {
                                  initialValue: episode.title || '',
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please enter the podcast name!'
                                    }
                                  ]
                                })(
                                  <Input
                                    disabled={isGenerating}
                                    onChange={e => {
                                      let title = e.target.value
                                      changeEpisodeTitle(title, i)
                                    }}
                                  />
                                )}
                              </Form.Item>
                              <Form.Item
                                label="Podcast Episode Description"
                                colon={false}
                                required={false}
                              >
                                {getFieldDecorator('description' + i, {
                                  initialValue: episode.description || '',
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        'Please enter the podcast desctiption!'
                                    }
                                  ]
                                })(
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={episode.description}
                                    onChange={(event, editor) => {
                                      const data = editor.getData()
                                      const plainText = data.replace(
                                        /<\/?[^>]+(>|$)/g,
                                        ''
                                      )
                                      const decodeEntities = str => {
                                        const textarea = document.createElement(
                                          'textarea'
                                        )
                                        textarea.innerHTML = str
                                        return textarea.value
                                      }
                                      const decodedText = decodeEntities(
                                        plainText
                                      )
                                      setEditorDescription(data, decodedText, i)
                                    }}
                                    config={{
                                      toolbar: [
                                        'heading',
                                        '|',
                                        'bold',
                                        'italic',
                                        'blockQuote',
                                        'numberedList',
                                        'bulletedList',
                                        'undo',
                                        'redo'
                                      ],
                                      removePlugins: [
                                        'link',
                                        'Image',
                                        'ImageUpload',
                                        'MediaEmbed',
                                        'InsertImage',
                                        'EasyImage',
                                        'Video'
                                      ]
                                    }}
                                  />
                                )}
                              </Form.Item>
                              <div
                                style={{
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '8px',
                                  padding: '2.4rem 2.4rem 0 2.4rem',
                                  marginBottom: '2.4rem',
                                  width: '100%'
                                }}
                              >
                                {episode.speakers.map(
                                  (speaker, speakerIndex) => {
                                    return (
                                      <Row
                                        gutter={[16, 8]}
                                        key={`${i}${speaker}${speakerIndex}`}
                                      >
                                        <Col xs={12} sm={12} md={10} lg={10}>
                                          <Form.Item
                                            label={
                                              i === 0
                                                ? 'Name of the Host'
                                                : `Name of the Guest No.${i +
                                                    1}`
                                            }
                                            colon={false}
                                            required={false}
                                          >
                                            {getFieldDecorator(speaker, {
                                              initialValue: speaker
                                            })(<Input disabled />)}
                                          </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                          <Form.Item
                                            label="Voice"
                                            colon={false}
                                            required={false}
                                          >
                                            {getFieldDecorator(
                                              speaker +
                                                '_voice' +
                                                '_e' +
                                                i +
                                                '_s' +
                                                speakerIndex,
                                              {
                                                initialValue:
                                                  voiceList[0].voice_id ||
                                                  undefined
                                              }
                                            )(
                                              <Select
                                                size="large"
                                                disabled={isGenerating}
                                              >
                                                {voiceList.map((el, i) => {
                                                  return (
                                                    <Option
                                                      value={el.voice_id}
                                                      key={i}
                                                    >
                                                      {el.name}
                                                    </Option>
                                                  )
                                                })}
                                              </Select>
                                            )}
                                          </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                          <Form.Item
                                            label=""
                                            colon={false}
                                            required={false}
                                          >
                                            <Button
                                              style={{ marginTop: '25px' }}
                                              loading={
                                                isVoiceLoading.id === speaker &&
                                                isVoiceLoading.status
                                              }
                                              disabled={
                                                (isVoiceLoading.id !==
                                                  speaker &&
                                                  isVoiceLoading.status) ||
                                                isGenerating
                                              }
                                              onClick={() => {
                                                setOpenAudioModal({
                                                  id: speaker,
                                                  open: true,
                                                  src: '',
                                                  index: i,
                                                  speakerIndex: speakerIndex
                                                })
                                              }}
                                            >
                                              <PlusCircleOutlined
                                                style={{ color: '#5057D5' }}
                                              />
                                              Library
                                            </Button>
                                          </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={2} lg={2}>
                                          <Form.Item
                                            label=""
                                            colon={false}
                                            required={false}
                                            style={{ marginTop: '25px' }}
                                          >
                                            <SoundOutlined
                                              onClick={() => {
                                                listenSample(
                                                  speaker,
                                                  i,
                                                  speakerIndex
                                                )
                                              }}
                                              style={{
                                                color: '#5057D5',
                                                fontSize: 20,
                                                padding: 6
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'left',
                                  alignItems: 'center',
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '8px',
                                  padding: '0 2.4rem',
                                  marginBottom: '2.4rem',
                                  width: '100%'
                                }}
                              >
                                <div
                                  style={{
                                    color: '#212121 !important',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    width: '55px'
                                  }}
                                >
                                  Music
                                </div>
                                <div
                                  style={{
                                    width: `calc(100% - 187px)`,
                                    paddingTop: '2.4rem'
                                  }}
                                >
                                  <Form.Item
                                    colon={false}
                                    required={false}
                                    style={{ padding: 0 }}
                                  >
                                    {getFieldDecorator('music_id' + i, {
                                      initialValue:
                                        formValues.music || undefined
                                    })(
                                      <Select
                                        disabled={isGenerating}
                                        placeholder={'Select music'}
                                        size="large"
                                        onChange={selectMusic}
                                        allowClear
                                      >
                                        {musicList.map(music => {
                                          return (
                                            <Option value={music.id}>
                                              {music.filename || music.title}
                                            </Option>
                                          )
                                        })}
                                      </Select>
                                    )}
                                  </Form.Item>
                                </div>
                                <div
                                  style={{
                                    width: '100px',
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Button
                                    loading={openMusicModal.isUploading}
                                    disabled={isGenerating}
                                    onClick={() => {
                                      setIsPaused(true)
                                      setMusicModal({
                                        ...openMusicModal,
                                        open: true,
                                        index: i
                                      })
                                    }}
                                  >
                                    <PlusCircleOutlined
                                      style={{ color: '#5057D5' }}
                                    />
                                    Add
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    width: '32px'
                                  }}
                                >
                                  {isPaused ? (
                                    <Tooltip
                                      placement="left"
                                      title={'Play the music'}
                                      arrow
                                    >
                                      <MutedOutlined
                                        onClick={() => {
                                          !isGenerating && listenMusic(false, i)
                                        }}
                                        style={{
                                          color: '#676973',
                                          fontSize: 20,
                                          padding: 6
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      placement="left"
                                      title={'Stop the music'}
                                      arrow
                                    >
                                      <SoundOutlined
                                        onClick={() => {
                                          listenMusic(true, i)
                                        }}
                                        style={{
                                          color: '#5057D5',
                                          fontSize: 20,
                                          padding: 6
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                colon={false}
                                required={false}
                                label={'Context'}
                                className="no_bottom_margin"
                              >
                                <div
                                  id="scrollableDiv"
                                  style={{
                                    height: 955,
                                    overflowY: 'auto',
                                    borderRadius: 10,
                                    padding: '1.2rem',
                                    border:
                                      '1px solid rgba(140, 140, 140, 0.35)'
                                  }}
                                >
                                  <List
                                    dataSource={episode.content}
                                    renderItem={(item, contentIndex) => (
                                      <List.Item
                                        key={i}
                                        actions={[
                                          !isGenerating &&
                                            ((isEdit.contentIndex !==
                                              contentIndex &&
                                              isEdit.index !== i) ||
                                              !isEdit.status) && (
                                              <a
                                                key="list-loadmore-edit"
                                                onClick={() => {
                                                  setIsEdit({
                                                    index: i,
                                                    contentIndex: contentIndex,
                                                    context: item.context,
                                                    status: true
                                                  })
                                                }}
                                              >
                                                Edit
                                              </a>
                                            ),
                                          !isGenerating &&
                                            isEdit.index === i &&
                                            isEdit.contentIndex ===
                                              contentIndex &&
                                            isEdit.status && (
                                              <a
                                                key="list-loadmore-save"
                                                onClick={() => {
                                                  updateContext(i, contentIndex)
                                                }}
                                              >
                                                Save
                                              </a>
                                            ),
                                          !isGenerating &&
                                            isEdit.index === i &&
                                            isEdit.contentIndex ===
                                              contentIndex &&
                                            isEdit.status && (
                                              <a
                                                key="list-loadmore-cancel"
                                                onClick={() => {
                                                  setIsEdit({
                                                    index: -1,
                                                    contentIndex: -1,
                                                    context: '',
                                                    status: false
                                                  })
                                                }}
                                              >
                                                Cancel
                                              </a>
                                            )
                                        ].filter(Boolean)}
                                      >
                                        <List.Item.Meta
                                          avatar={
                                            <Avatar
                                              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                                                item.speaker
                                              )}`}
                                            />
                                          }
                                          title={
                                            <div>
                                              <a>{item.speaker}</a>
                                            </div>
                                          }
                                          description={
                                            isEdit.index === i &&
                                            isEdit.contentIndex ===
                                              contentIndex &&
                                            isEdit.status ? (
                                              <Input.TextArea
                                                rows={3}
                                                defaultValue={isEdit.context}
                                                onChange={e =>
                                                  handleInputChange(e)
                                                }
                                              />
                                            ) : (
                                              item.context
                                            )
                                          }
                                        />
                                      </List.Item>
                                    )}
                                  />
                                </div>
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <div className="discussion-machine-main-body-action">
                                <div className="generate-btn">
                                  <Tooltip
                                    placement="left"
                                    title={
                                      episode.description
                                        ? 'This will generate the audio of the content according to selected voices.'
                                        : 'Please fill in all the required fields before submitting.'
                                    }
                                    arrow
                                  >
                                    <span>
                                      <Button
                                        type="primary"
                                        className="rounded"
                                        loading={isGenerating}
                                        onClick={() => {
                                          generateAudio(i)
                                        }}
                                      >
                                        {episode.link
                                          ? 'Regenerate to Voice'
                                          : 'Regenerate to Voice'}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                                <div className="generate-audio">
                                  {episode.link && (
                                    <ReactPlayer
                                      url={episode.link}
                                      playing={
                                        !isSaving &&
                                        tabIndex === JSON.stringify(i + 1)
                                      }
                                      controls={true}
                                      width="100%"
                                      height="50px"
                                      config={{
                                        file: {
                                          forceAudio: true
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      )
                    })}
                </Tabs>
              </Fragment>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  )
}

export default Form.create({ name: 'podcast_form' })(DiscussionCreateMachine)
