import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Form,
  Modal,
  Menu,
  Dropdown,
  Spin,
  message,
  Tooltip
} from 'antd'
import ImageCropContainer from '../../ImageCrop'
import { general, config } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../../store/actions'
import { PopupMedia } from '../../../views/Library/internal'
import _ from 'lodash'
import AiImages from '../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Image/components/AiImages'
import {
  uploadImage,
  uploadMedia,
  uploadAi,
  uploadCanve
} from '../../Core/ImageUpload/images'
import './imageSelect.scss'
import ReactSVG from 'react-svg'
import EditIcon from '../../../assets/edit.svg'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from '../../../services'
import { DropzoneInner, DropzoneLoading, DropzoneWrapper } from './styled'
import { InfoCircleOutlined } from '@ant-design/icons'

function InsertMedia(props) {
  const {
    form,
    onCoverChange,
    coverInput,
    label,
    clearUploadList,
    tooltip,
    isBackground = false,
    required = true
  } = props

  console.log({
    imgUploadProps: props
  })
  const [file, setFile] = useState(null)
  const [image, setImage] = useState(null)
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [isMediaLibraryModalOpen, setIsMediaLibraryModalOpen] = useState(false)
  const [imageName, setImageName] = useState('')
  const [imageSize, setImageSize] = useState('')
  const [isAiModal, setAiModal] = useState(false)
  const [cover, setCover] = useState({
    id: null,
    link: '',
    file_size: '',
    filename: ''
  })
  const [isPreviewModal, setPreviewModal] = useState(false)
  const [imageSelected, setImageSelected] = useState(false)
  const [loading, setLoading] = useState(false)

  const inputFileRef = useRef(null)

  const user = useSelector(state => state.user)

  const dispatch = useDispatch()
  useEffect(() => {
    setImageName(coverInput && coverInput.filename)
    setImageSize(coverInput && coverInput.file_size)
    setCover({
      id: coverInput && coverInput.id,
      link: coverInput && coverInput.link
    })
    setImageSelected(!!coverInput)
  }, [coverInput])

  const onDrop = (dropped, handleAfterUpload, handlebeforeUpload) => {
    const file = dropped[0]
    if (!file) {
      return
    }

    const image = URL.createObjectURL(file)
    setImage(image)
    setFile(file)
    setImageName(file.name)
    setImageSize(file.size)
    clearUploadList()
    handleAfterUpload = () => {
      setLoading(false)
      setImageSelected(true)
    }

    handlebeforeUpload = () => {
      setLoading(true)
    }
  }

  const { t } = useTranslation()

  const handleUploadImage = data => {
    setIsImageLoading(true)
    dispatch(actions.library.upload(data)).then(
      ({ id, link, file_size, filename }) => {
        setCover({ id, link, file_size, filename })
        onCoverChange({ id, link, file_size, filename })
        setImage(null)
        setFile(null)
        setIsImageLoading(false)
        setImageSelected(true)
      }
    )
  }

  const useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  /**
   * Fetches an image from a URL and sends it to a handleUploadImage function.
   * @param {string} url - The URL of the image to be fetched.
   * @param {string} title - The title of the image.
   */
  const handleImageUrl = async (url, title) => {
    try {
      const userData = user && user.info
      setIsImageLoading(true)

      const response = await api.imageGeneration.generateIdeogramImage({
        download_url: url,
        user_id: userData && userData.id,
        active_org_id: userData && userData.active_organization_id
      })

      console.log('response', response)
      if (response && response.data) {
        console.log('response', response.data)
        handleSelectedImage(response.data)
      }
    } catch (error) {
      console.log(error)
      message.error(error.message)
    } finally {
      setIsImageLoading(false)
    }
  }

  const handleClosePopup = type => {
    if (type === general.SAVE) {
      setImage(null)
    } else {
      setImage(null)
      setFile(null)
    }
  }

  const handleSelectedImage = image => {
    const newCover = {
      id: image.id,
      link: image.link,
      file_size: image.file_size,
      filename: image.filename
    }
    setCover(newCover)
    onCoverChange(newCover)
    setIsMediaLibraryModalOpen(false)
    setAiModal(false)
    setImageSelected(true)
    setImageName(image.filename)
    setImageSize(image.file_size)
  }

  const handleFileInputClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      onDrop([file])
    }
  }

  const onImage = item => {
    handleImageUrl(item.link, 'Presentation ai')
    setAiModal(false)
  }
  const handleImageClick = () => {
    setPreviewModal(true)
  }
  const menu = (
    <Menu className="secondary-image-upload">
      <Menu.Item key="1" onClick={e => handleFileInputClick(e.domEvent)}>
        <ReactSVG src={uploadImage} />
        {t('buttons:upload_image')}
      </Menu.Item>
      <Menu.Item key="2" onClick={e => setIsMediaLibraryModalOpen(e.domEvent)}>
        <ReactSVG src={uploadMedia} />
        {t('buttons:select_media_library')}
      </Menu.Item>
      <Menu.Item key="3" onClick={e => useCanva(e.domEvent)}>
        <ReactSVG src={uploadCanve} />
        {t('v4:canva')}
      </Menu.Item>
      <Menu.Item key="4" onClick={e => setAiModal(true)}>
        <ReactSVG src={uploadAi} />
        {t('v3:ai_creator')}
      </Menu.Item>
    </Menu>
  )

  console.log({
    imageSelected,
    cover
  })

  return (
    <div className="upload-btn-wrap">
      {isAiModal && (
        <Modal
          visible={isAiModal}
          footer={false}
          destroyOnClose
          centered
          onCancel={e => {
            e.preventDefault()
            setAiModal(false)
          }}
          width={'80%'}
        >
          <AiImages
            isBackground={isBackground}
            selectedImage={cover}
            onImage={onImage}
          />
        </Modal>
      )}

      <Form.Item
        style={{ marginBottom: 0 }}
        label={
          <div>
            {label || t('labels:picture')} &nbsp;
            {tooltip && (
              <Tooltip placement="top" title={tooltip} arrow>
                <InfoCircleOutlined style={{ color: '#5057D5' }} />
              </Tooltip>
            )}
          </div>
        }
        colon={false}
        required={false}
      >
        {imageSelected ? (
          <Spin spinning={loading} tip="Uploading Image">
            <div className="shl-image-upload">
              <div className="shl-image-upload-inner">
                <div
                  className="shl-image-image-wrap"
                  onClick={handleImageClick}
                >
                  <div className="shl-image-data">
                    <img src={cover && cover.link} alt="media" />
                  </div>
                  <div className="shl-image-info">
                    <div>
                      <label>Image name:</label>
                      <span>{imageName || 'N/A'}</span>
                    </div>
                    <div>
                      <label>Image size:</label>
                      <span>
                        {imageSize
                          ? parseFloat(imageSize / 1024).toFixed(1) + ' KB'
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="shl-image-choose">
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <span className="image-edit" size="large">
                      <img src={EditIcon} alt="" />
                    </span>
                  </Dropdown>
                  <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/jpeg, image/png"
                  />
                </div>
              </div>
            </div>
          </Spin>
        ) : (
          <>
            {form &&
              form.getFieldDecorator(label || 'cover', {
                rules: [
                  {
                    required: required,
                    message: t('v3:required_cover_image')
                  }
                ]
              })(
                <DropzoneWrapper
                  multiple={false}
                  onDrop={onDrop}
                  accept="image/jpeg, image/png"
                  disabled={isImageLoading}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return isImageLoading ? (
                      <DropzoneLoading type="loading" />
                    ) : (
                      <DropzoneInner>
                        <Button className="rounded upload-btn" size="small">
                          <ReactSVG src={uploadImage} />
                          {t('buttons:upload_image')}
                        </Button>

                        <Button
                          className="rounded upload-btn"
                          size="small"
                          onClick={e => {
                            e.stopPropagation()
                            setIsMediaLibraryModalOpen(true)
                          }}
                        >
                          <ReactSVG src={uploadMedia} />
                          {t('buttons:select_media_library')}
                        </Button>

                        <Button
                          className="rounded upload-btn"
                          size="small"
                          onClick={e => {
                            e.stopPropagation()
                            useCanva()
                          }}
                        >
                          <ReactSVG src={uploadCanve} />
                          {t('v4:canva')}
                        </Button>

                        <Button
                          className="rounded upload-btn"
                          size="small"
                          onClick={e => {
                            e.preventDefault()
                            setAiModal(true)
                          }}
                        >
                          <ReactSVG src={uploadAi} />
                          {t('v3:ai_creator')}
                        </Button>
                      </DropzoneInner>
                    )
                  }}
                </DropzoneWrapper>
              )}
          </>
        )}
        {isPreviewModal && (
          <Modal
            visible={isPreviewModal}
            footer={false}
            onCancel={() => setPreviewModal(false)}
            className="image-popup-show"
            centered
          >
            <img src={cover.link} alt="Preview" style={{ width: '100%' }} />
          </Modal>
        )}

        {image && (
          <ImageCropContainer
            image={image}
            handleUploadImage={handleUploadImage}
            handleClosePopup={handleClosePopup}
            file={file}
            t={t}
          />
        )}

        {isMediaLibraryModalOpen && (
          <OrganizationImageSelect
            visible={isMediaLibraryModalOpen}
            onClose={() => setIsMediaLibraryModalOpen(false)}
            onSelectImage={handleSelectedImage}
          />
        )}
      </Form.Item>
    </div>
  )
}

const OrganizationImageSelect = ({ onClose, visible, onSelectImage }) => {
  const handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const item = _.head(tempData.filter(value => value.isSelected))
    onSelectImage(item)
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={'80%'}
      centered
    >
      <div className="wrap_modal_tabs">
        <PopupMedia
          isPageBuilder
          handleInsertData={handleInsertData}
          types="images"
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  library: state.library
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearUploadList: actions.library.clearUploadList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(InsertMedia)
