import React, { Component, createRef } from 'react'
import _ from 'lodash'
import {
  Form,
  Icon,
  Input,
  InputNumber,
  Button,
  Spin,
  Modal,
  Select,
  message
} from 'antd'
import { withTranslation } from 'react-i18next'

import { Row, Col } from '../../../../../styles'
import { config, general, topics } from '../../../../../constants'
import { Editor } from '../../../../../components/ImageEditor'
import { PopupMedia } from '../../../../../views/Library/internal'
import { EditImageContainer } from '../../../../../components/TextOnImage'
import CourseDropzone from '../../../../Courses/Create/internal/CourseDropzone'

import Badge from '../../../../Courses/Create/nestedComponents/CourseCreateMain/components/Badge'
import Certificate from '../../../../Courses/Create/nestedComponents/CourseCreateMain/components/Certificate'
import { AudioModalTitleIcon } from '../../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/Icons'
import FormHeader from './FormHeader'
import MazeRightPanel from './MazeRightPanel'
import { TopSection, MiddleSection, BottomSection } from './Marketplace'
import { AllowAccess, TopicsInput } from '../../../../../components/Form'
import './styles.scss'

class MazeCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMarketPlace: false,
      topics: [],
      isCover: false,
      isCoverLoading: false,
      isBackgroundType: '',
      mazeBackground: null,
      mazeAvatar: null,
      isGettingVoice: false,
      mazeBackgroundError: false,
      openBadgeModal: false,
      openCertificateModal: false,
      isCertificateLoading: false,
      isBadgeLoading: false,
    }
    this.imageRef = createRef()
    this.audioRef = createRef()
  }

  componentDidMount() {
    const {
      maze: {
        mazeById: { id }
      },
      getVoices
    } = this.props

    getVoices()

    if (id) {
      this.startMazeCreation()
      this.checkMarketplaceCompletion()
    }
  }

  startMazeCreation = () => {
    const {
      maze: {
        mazeById: {
          id,
          topic_list = [],
          background_image_data,
          avatar,
          avatar_voice
        }
      }
    } = this.props

    if (id) {
      this.setState(
        {
          topics: topic_list,
          mazeBackground: background_image_data,
          mazeAvatar: avatar
        },
        () => {
          this.filterVoicesByGender(avatar_voice || null)
        }
      )
    }
  }

  getOldLanguage = voiceType => {
    const { voices } = this.state
    const voiceItem =
      voices && voices.filter(item => item.voiceType === voiceType)[0]
    if (voiceItem) {
      this.handleLanguageSelect(voiceItem.language, true)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const {
        audioSynthesizer: { loadAudioData },
        maze: { mazeById }
      } = this.props

      const id = mazeById && mazeById.id

      const prevId = prevProps.maze.mazeById

      if (id !== prevId && prevId.id) {
        this.startMazeCreation()
      }

      if (loadAudioData) {
        var voices = []
        Promise.resolve(
          Object.keys(loadAudioData).forEach(item => {
            voices.push({
              voiceType: item,
              ...loadAudioData[item]
            })
          })
        ).then(() =>
          this.setState({
            voices
          })
        )
      }
    }
  }

  sortVoicesByLanguage = (voices = [], avatarVoice) => {
    var sorted = {}
    for (var i = 0, max = voices.length; i < max; i++) {
      if (sorted[voices[i].language] === undefined) {
        sorted[voices[i].language] = []
      }
      sorted[voices[i].language].push(voices[i])
    }

    var languages = []
    Object.keys(sorted).forEach(item => languages.push(item))

    this.setState(
      {
        voices,
        voicesByLanguage: sorted,
        languages
      },
      () => {
        if (avatarVoice) {
          this.getOldLanguage(avatarVoice)
        }
      }
    )
  }

  handleTopicsChange = newValue => {
    this.setState(
      {
        topics: newValue
      },
      () => {
        this.handleValue('mazeTags', _.map(newValue, 'id'))
      }
    )
  }

  handleSubmit = _.debounce(() => {
    const {
      mazeBuilder: { mazeId, saving },
      maze: { updatingMaze }
    } = this.props

    if ((mazeId && updatingMaze) || saving) {
      this.handleSubmit()
    } else {
      this.submitData()
    }
  }, 1000)

  checkMarketplaceCompletion = () => {
    const {
      mazeBuilder: {
        mazeId,
        mazeTitle,
        mazeImgId,
        mazeCategory,
        mazeIsFree,
        mazeMarketPlaceDescription,
        mazeObjectives,
        mazeOutline,
        mazePrice,
        mazeVideoLink
      }
    } = this.props
    let data = {
      mazeId,
      mazeTitle,
      mazeImgId,
      mazeCategory,
      mazeIsFree,
      mazeMarketPlaceDescription,
      mazeObjectives,
      mazeOutline,
      mazePrice,
      mazeVideoLink
    }
    let errorData = Object.keys(data).filter(k => {
      if (data[k] === '' || data[k] === undefined || data[k] === null) {
        return true
      } else {
        return false
      }
    })

    if (errorData.length !== 0) {
      message.error('Marketplace information is not complete.')
    } else {
      message.success('Marketplace information is complete.')
    }
  }

  submitData = async () => {
    const {
      form,
      user: {
        info: { username, bio, linkedin }
      },
      mazeBuilder: {
        mazeImgId,
        mazeOrganiser,
        mazeImg,
        mazeId,
        mazeDescription,
        mazeVoice,
        mazeVoiceType,
        mazeVoiceAccent,
        mazeSelectedVoice,
        mazeBadgeId,
        mazeCertId,
        mazeAvatarId,
        mazeCategory,
        mazeIsFree,
        mazePrice,
        mazeVideoLink,
        mazeObjectives,
        mazeOutline,
        mazePassScore,
        mazeMarketPlaceDescription,
        mazeShareProfile,
        mazeHostEmails
      },
      mazeBuilderActions: { createMaze },
      updateMaze
    } = this.props
    const { topics, mazeBackground, mazeAvatar } = this.state

    console.log('Submitting data..., ', this.props.mazeBuilder && this.props.mazeBuilder)

    try {
      // Validate form fields
      await form.validateFields((err, values) => {
        if (mazeBackground) this.setState({ mazeBackgroundError: false })
        else this.setState({ mazeBackgroundError: true })

        if (
          !values.title ||
          !mazePassScore ||
          !mazeImg ||
          !mazeBackground ||
          !mazeAvatar
        ) {
          throw new Error('Required fields are missing.')
        }

        if (err) {
          const errorParams = Object.keys(err)
          if (
            errorParams.find(
              item => item !== 'mazeLayers' && item !== 'mazeOptions'
            )
          ) {
            throw new Error('Form validation failed.')
          }
        }

        // Prepare the maze data
        let mazeData = {
          body: {
            title: values.title,
            minimum_pass_score: mazePassScore,
            avatar_voice: mazeVoice, // voice name
            author: mazeOrganiser,
            cover_id: mazeImgId,
            background_image: Number(mazeBackground && mazeBackground.id),
            avatar_id: mazeVoiceType === "elevenlabs" ? mazeAvatarId : Number(mazeAvatar && mazeAvatar.id), //gender
            voice_type: mazeVoiceType,
            voice_accent: mazeVoiceAccent,
            voice_name: mazeSelectedVoice,
            //voice_age: "middle-aged",
            description: mazeDescription,
            host_emails: mazeHostEmails,
            badge_id: mazeBadgeId,
            certificate_id: mazeCertId
          },
          action: 'create_programme'
        }
        mazeData.body.topics = JSON.stringify(_.map(topics, 'id'))

        // If mazeId exists, update the maze; otherwise, create a new one
        if (mazeId) {
          if (mazeCategory) {
            mazeData.body.marketplace = {
              username: username,
              category_id: mazeCategory,
              is_free: mazeIsFree,
              price: !mazeIsFree && !mazePrice ? '5' : mazePrice,
              video_link: mazeVideoLink,
              course_obj: mazeObjectives,
              course_outline: mazeOutline,
              is_shared_profile: mazeShareProfile,
              course_description: mazeMarketPlaceDescription,
              bio,
              linkedin
            }
          }

          // Update the maze
          updateMaze({
            id: mazeId,
            data: mazeData.body
          })
        } else {
          // Create a new maze
          createMaze(mazeData)
        }
      })
    } catch (error) {
      console.error('Error submitting maze data:', error.message)
      // Optionally, display an error message to the user
      message.error(error.message)
    }
  }

  handleValue = (field, value) => {
    const { mazeBuilderActions } = this.props
    mazeBuilderActions.handleMazeInput(field, value)
    this.handleSubmit()
  }

  handleTitle = _.debounce(v => this.handleValue('mazeTitle', v), 1000)
  handleCategory = _.debounce(v => this.handleValue('mazeCategory', v), 1000)
  handleSwitch = _.debounce(v => this.handleValue('mazeIsFree', v), 1000)
  handlePrice = _.debounce(v => this.handleValue('mazePrice', v), 1000)
  handleVideoLink = _.debounce(v => this.handleValue('mazeVideoLink', v), 1000)
  handleOrganiser = _.debounce(v => this.handleValue('mazeOrganiser', v), 1000)
  handleTopics = _.debounce(v => this.handleValue('mazeTags', v), 1000)
  handleDescription = _.debounce(
    v => this.handleValue('mazeDescription', v),
    1000
  )
  handleMarketPlaceDescription = _.debounce(
    v => this.handleValue('mazeMarketPlaceDescription', v),
    1000
  )
  handleObjectives = _.debounce(
    v => this.handleValue('mazeObjectives', v),
    1000
  )
  handleProfile = _.debounce(v => this.handleValue('mazeShareProfile', v), 1000)
  handleOutline = _.debounce(v => this.handleValue('mazeOutline', v), 1000)
  handlePassScore = _.debounce(v => this.handleValue('mazePassScore', v), 1000)
  handleLayers = _.debounce(v => this.handleValue('mazeLayers', v), 1000)
  handleOptions = _.debounce(v => this.handleValue('mazeOptions', v), 1000)

  handleVoiceSelect = type => {
    this.getVoiceSample(type)
    this.handleValue('mazeVoice', type)
    this.setState({ selectedAudio: type }, () => this.handleSubmit())
  }

  handleElevenLabsVoiceSelect = type => {
    if (Object.keys(type).length > 0) {
      if (type.selectedGender === "male") {
        this.handleValue('mazeAvatarId', 48683)
      } else {
        this.handleValue('mazeAvatarId', 48682)
      }

      this.handleValue('mazeVoice', type.selectedId)
      this.handleValue('mazeVoiceType', type.selectedVoiceType)
      this.handleValue('mazeVoiceAccent', type.selectedAccent)
      this.handleValue('mazeSelectedVoice', type.selectedVoice)
    }
  }

  getVoiceSample = audioType => {
    const {
      audioSynthesizer: { loadAudioData }
    } = this.props

    this.setState(
      {
        isGettingVoice: false,
        audioSrc: loadAudioData[audioType].sample
      },
      () => {
        if (this.audioRef.current) {
          this.audioRef.current.pause()
          this.audioRef.current.load()
          this.audioRef.current.play()
        }
      }
    )
  }

  onDrop = (file, handleAfterUpload, handlebeforeUpload) => {
    const image = URL.createObjectURL(file)
    this.handleAfterUpload = handleAfterUpload
    this.handlebeforeUpload = handlebeforeUpload
    this.setState({
      file,
      image
    })
  }

  useCanva = type => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: type
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle, type)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title.split(' ').join('_'),
      size: blob.size,
      type: blob.type
    }
    this.handleUploadImage(body)
  }

  handleUploadImage = file => {
    const { upload, mazeBuilderActions } = this.props
    this.handlebeforeUpload && this.handlebeforeUpload()
    this.setState({
      isCoverLoading: true
    })
    return upload(file)
      .then(res => {
        const { id, link } = res
        mazeBuilderActions.handleMazeInput('mazeImgId', id)
        mazeBuilderActions.handleMazeInput('mazeImg', link)
        this.handleImageSuccess(res)
      })
      .catch(() => {
        this.handleAfterUpload && this.handleAfterUpload()
      })
  }

  handleImageSuccess = res => {
    const { id } = res

    if (id) {
      this.setState(
        {
          isCover: false,
          isCoverLoading: false,
          file: null,
          image: null,
          isImageLoading: false
        },
        () => {
          this.handleAfterUpload && this.handleAfterUpload()
        }
      )
    }
    this.handleSubmit()
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
      this.handleAfterUpload()
    }
  }

  hideModal = () => {
    this.setState({
      openImagesModal: false,
      isBackgroundType: '',
      modalOpen: false
    })
  }

  hideVoiceModal = () => {
    this.setState({
      openCreateVoiceModal: false
    })
  }

  onShowDetailModal = src => {
    this.setState({ src: src, isShowDetailModal: true })
  }

  onHideDetailModal = () => {
    this.setState({ isShowDetailModal: false })
  }

  handleFileUpload = files => {
    if (!files) return
    this.setState({ selectedAudio: files[0] })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const { isBackgroundType } = this.state

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    if (isBackgroundType === 'scene') {
      this.handleValue('mazeBackground', selectedItem.id)
      this.handleValue('mazeVoice', '')
      this.setState(
        {
          isBackgroundType: '',
          selectedLanguage: undefined,
          mazeBackground: selectedItem,
          isBackgroundLoading: false,
          openImagesModal: false,
          audioSrc: ''
        },
        () => {
          this.handleSubmit()
        }
      )
    } else if (isBackgroundType === 'avatar') {
      this.handleValue('mazeAvatarId', selectedItem.id)
      this.setState(
        {
          isBackgroundType: '',
          selectedLanguage: undefined,
          mazeAvatar: selectedItem,
          isBackgroundLoading: false,
          openImagesModal: false,
          audioSrc: ''
        },
        () => {
          this.handleSubmit()
          this.filterVoicesByGender()
        }
      )
    } else {
      this.setState({
        selectedItem
      })
    }
  }

  filterVoicesByGender = avatarVoice => {
    const {
      voices,
      mazeAvatar: { avatar_gender }
    } = this.state

    const voicesByGender =
      voices &&
      voices.filter(
        item =>
          item.gender.toLowerCase() === avatar_gender &&
          avatar_gender.toLowerCase()
      )

    this.sortVoicesByLanguage(voicesByGender, avatarVoice)
  }

  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current
      ? this.imageRef.current.imageEditorInst
      : null

    // Check if imageEditorInst exists before accessing its properties
    const isEdited = imageEditorInst
      ? !imageEditorInst.isEmptyUndoStack()
      : false

    if (isEdited) {
      // Upload the edited image
      const data = imageEditorInst.toDataURL()
      const { mazeBuilderActions } = this.props
      const { id } = item

      // Set courseImgId and courseImg in courseBuilder state
      mazeBuilderActions.handleMazeInput('mazeImgId', id)
      mazeBuilderActions.handleMazeInput('mazeImg', data)

      const body = {
        croppedImage: data,
        lastModifiedDate: new Date(),
        name: item.filename,
        size: item.file_size,
        type: item.media_mime_type
      }

      this.setState(
        {
          isImageLoading: true,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          // Upload the edited image
          this.handleUploadImage(body)
        }
      )
    } else {
      // Use the original image URL
      const { mazeBuilderActions } = this.props
      const { id, link } = item

      // Set courseImgId and courseImg in courseBuilder state
      mazeBuilderActions.handleMazeInput('mazeImgId', id)
      mazeBuilderActions.handleMazeInput('mazeImg', link)

      this.setState(
        {
          isImageLoading: true,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          // Process the original image URL
          this.handleImageSuccess(item)
        }
      )
    }
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  handleShowTextModal = () => {
    this.setState({ showTextModal: !this.state.showTextModal })
  }

  onToggleMarketPlace = () => {
    this.setState({ isMarketPlace: !this.state.isMarketPlace }, () => {
      if (!this.state.isMarketPlace) {
        this.checkMarketplaceCompletion()
      }
    })
  }

  handleNext = () => {
    const {
      history,
      id,
      mazeBuilder: { mazeId }
    } = this.props
    if (!mazeId) return
    history.push(`/maze/${mazeId || id}/edit/scenario`)
  }

  handleBackgroundChange = () => {
    this.setState({
      isBackgroundType: 'scene',
      openImagesModal: true
    })
  }

  handleAvatarChange = () => {
    this.setState({
      isBackgroundType: 'avatar',
      openImagesModal: true
    })
  }

  onAddVoice = () => {
    this.handleValue('mazeVoiceType', 'traditional')
    this.handleValue('mazeVoiceAccent', undefined)
    this.handleValue('mazeSelectedVoice', undefined)
    this.setState(
      {
        openCreateVoiceModal: true
      },
      () => {
        const {
          mazeBuilder: { mazeVoice }
        } = this.props
        const { audioSrc } = this.state
        // if (mazeVoice && !audioSrc) {
        //   this.getVoiceSample(mazeVoice)
        // }
      }
    )
  }

  handleLanguageSelect = (val, isMount) => {
    const { voicesByLanguage } = this.state
    const filteredVoices = voicesByLanguage && voicesByLanguage[val]

    if (isMount) {
      this.setState({
        selectedLanguage: val,
        filteredVoices
      })
    } else {
      this.handleValue('mazeVoice', undefined)
      this.setState(
        {
          selectedLanguage: val,
          filteredVoices,
          audioSrc: ''
        },
        () => this.filterVoicesByGender()
      )
    }
  }

  validateLayers = (rule, value, callback) => {
    if (value && (value < 2 || value > 8)) {
      callback('Only 2 to 8 layers')
    }

    callback()
  }

  validateOptions = (rule, value, callback) => {
    if (value && (value < 2 || value > 4)) {
      callback('Only 2 to 4 options')
    }

    callback()
  }

  toggleBadgeModal = () => {
    this.setState(state => ({ openBadgeModal: !state.openBadgeModal }))
  }

  toggleCertificateModal = () => {
    this.setState(state => ({
      openCertificateModal: !state.openCertificateModal
    }))
  }

  badgePresentationUpload = (body, imageType) => {
    const { upload } = this.props
    this.toggleLoadingState(imageType)
    this.setState({ openBadgeModal: false, openCertificateModal: false })
    upload(body)
      .then(res => {
        const { id } = res
        this.toggleLoadingState(imageType)
        this.handleRewardImageUpload(imageType, id)
      })
      .catch(() => {
        this.toggleLoadingState(imageType)
        message.error('Error occured. Try Again !')
      })
  }

  toggleLoadingState = type => {
    if (type === 'Certificate') {
      this.setState(state => ({
        isCertificateLoading: !state.isCertificateLoading
      }))
    } else {
      this.setState(state => ({ isBadgeLoading: !state.isBadgeLoading }))
    }
  }

  handleRewardImageUpload = (type, id) => {
    const { mazeBuilderActions } = this.props
    if (type === 'Certificate') {
      mazeBuilderActions.handleMazeInput('mazeCertId', id)
    } else {
      mazeBuilderActions.handleMazeInput('mazeBadgeId', id)
    }
    this.handleSubmit()
  }

  handleMarketPlaceOption = marketPlaceOption => {
    this.props.mazeBuilderActions.handleMazeInput(
      'mazeMarketplaceOption',
      marketPlaceOption
    )
    this.handleSubmit()
  }

  onEmailsChange = emails => {
    this.handleValue('mazeHostEmails', emails)
  }

  render() {
    const {
      form: { getFieldDecorator },
      mazeBuilder: {
        mazeId,
        mazeImg,
        mazeTitle,
        mazeImgId,
        mazeLayers,
        mazeOptions,
        mazeOrganiser,
        mazePassScore,
        mazeBadgeId,
        mazeCertId,
        mazeVoice,
        mazeVoiceType,
        mazeSelectedVoice,
        mazeVoiceAccent,
        mazeAvatarId,
        mazeHostEmails
      },
      maze: { fetchById, updatingMaze },
      t
    } = this.props

    const { maze: { mazeById: { voice_type, voice_accent, avatar_id, voice_name } } } = this.props

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const modal_style = {
      overflow: 'auto',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }

    const {
      file,
      image,
      topics,
      audioSrc,
      modalOpen,
      languages,
      selectedItem,
      isBackgroundType,
      isMarketPlace,
      filteredVoices,
      mazeBackground,
      mazeAvatar,
      isImageLoading,
      isCoverLoading,
      isBadgeLoading,
      openBadgeModal,
      openImagesModal,
      selectedLanguage,
      isBackgroundLoading,
      mazeBackgroundError,
      openCreateVoiceModal,
      isCertificateLoading,
      openCertificateModal
    } = this.state

    const audioModalTitle = (
      <AudioModalTitleIcon
        t={t}
        isShowCreateAudioModal={openCreateVoiceModal}
      />
    )

    return (
      <Spin className="global_ant_spin" spinning={fetchById}>
        <section className="section" style={{ paddingBottom: '10rem' }}>
          <FormHeader
            t={t}
            mazeId={mazeId}
            isMarketPlace={isMarketPlace}
            onToggleMarketPlace={this.onToggleMarketPlace}
          />
          <Form>
            <div className="create-course">
              <div className="create-course__content">
                <Row>
                  <Col md={isMarketPlace ? 12 : 6}>
                    <Form.Item
                      colon={false}
                      required={false}
                      label={t('labels:title')}
                    >
                      {getFieldDecorator('title', {
                        rules: [
                          {
                            required: true,
                            message: t('v4:input_title_maze')
                          },
                          {
                            whitespace: true,
                            pattern: '.{3,}',
                            message: t('errors:min_3char')
                          }
                        ],
                        initialValue: mazeTitle
                      })(
                        <Input
                          size="large"
                          maxLength={100}
                          placeholder={t('placeholders:title')}
                          onChange={e => this.handleTitle(e.target.value)}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  {!isMarketPlace && (
                    <Col md={6}>
                      <Form.Item
                        colon={false}
                        required={false}
                        label={t('labels:organiser')}
                      >
                        {getFieldDecorator('organiser', {
                          initialValue: mazeOrganiser
                        })(
                          <Input
                            size="large"
                            tighter="true"
                            addonBefore={t('general:by')}
                            placeholder={t('placeholders:organiser')}
                            onChange={e => this.handleOrganiser(e.target.value)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {isMarketPlace && (
                  <TopSection
                    props={this.props}
                    onChangeAuthor={val => this.handleOrganiser(val)}
                  />
                )}
                <Row>
                  <Col md={isMarketPlace ? 12 : 6}>
                    <TopicsInput
                      defaultTopics={topics}
                      onTopicsChange={this.handleTopicsChange}
                    />
                  </Col>
                  {!isMarketPlace && (
                    <Col md={6}>
                      <Form.Item
                        colon={false}
                        required={false}
                        label={t('v4:pass_score')}
                      >
                        {getFieldDecorator('pass_score', {
                          initialValue: mazePassScore,
                          rules: [
                            {
                              validator: (rule, value) =>
                                value > 0 && value <= 100,
                              message: t('v4:pass_score_mark')
                            }
                          ]
                        })(
                          <InputNumber
                            size="large"
                            tighter="true"
                            style={{ width: '100%' }}
                            onChange={value => this.handlePassScore(value)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {!isMarketPlace && (
                  <AllowAccess
                    form={this.props.form}
                    onChange={this.onEmailsChange}
                    defaultHostEmails={mazeHostEmails}
                  />
                )}
                <Row>
                  <div className="canva-editor-items">
                    <Button
                      className="item"
                      shape="round"
                      icon="pic-right"
                      onClick={this.toggleCertificateModal}
                      loading={isCertificateLoading}
                      style={{
                        backgroundColor: mazeCertId ? 'green' : null,
                        color: mazeCertId ? 'white' : null
                      }}
                    >
                      {mazeCertId
                        ? t('v4:certificate_added')
                        : t('v4:create_certificate')}
                      {mazeCertId && <Icon type="check" />}
                    </Button>
                    <Button
                      className="item"
                      icon="security-scan"
                      onClick={this.toggleBadgeModal}
                      shape="round"
                      loading={isBadgeLoading}
                      style={{
                        backgroundColor: mazeBadgeId ? 'green' : null,
                        color: mazeBadgeId ? 'white' : null
                      }}
                    >
                      {mazeBadgeId ? t('v4:badge_added') : t('v4:create_badge')}
                      {mazeBadgeId && <Icon type="check" />}
                    </Button>
                    <span className="text">
                      <Icon type="warning" /> {t('v4:certificate_warning')}
                    </span>
                  </div>
                  <div className="maze-options">
                    <div className="maze-options__title">
                      Create auto flow
                      <span>(Option)</span>
                    </div>
                    <div className="maze-options__values">
                      <div className="maze-options__values-item">
                        <span>How many layers</span>
                        <Form.Item>
                          {getFieldDecorator('mazeLayers', {
                            initialValue: mazeLayers,
                            rules: [
                              {
                                required: false,
                                validator: this.validateLayers
                              }
                            ]
                          })(
                            <InputNumber
                              onChange={value => this.handleLayers(value)}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className="maze-options__values-item">
                        <span>How many options</span>
                        <Form.Item>
                          {getFieldDecorator('mazeOptions', {
                            initialValue: mazeOptions,
                            rules: [
                              {
                                required: false,
                                validator: this.validateOptions
                              }
                            ]
                          })(
                            <InputNumber
                              onChange={value => this.handleOptions(value)}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Item
                      colon={false}
                      required={true}
                      label={t('labels:picture')}
                      className="no_bottom_margin"
                    >
                      <CourseDropzone
                        app={this}
                        onDrop={this.onDrop}
                        error={mazeImgId ? 0 : 1}
                        disabled={isCoverLoading}
                        cover={mazeImgId && mazeImg}
                        // handleAiImage={this.handleFileInsert}
                        handleAiImage={({ id, link }) =>
                          this.handleImageUrl(link, 'ai_generated_image')
                        }
                        loadImage={isImageLoading || isCoverLoading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {isMarketPlace && (
                  <MiddleSection
                    {...this.props}
                    onPrice={this.handlePrice}
                    onSwitch={this.handleSwitch}
                    onCategory={this.handleCategory}
                    onVideoLink={this.handleVideoLink}
                  />
                )}
              </div>
              {!isMarketPlace && (
                <Col md={4}>
                  <MazeRightPanel
                    onAddBackground={this.handleBackgroundChange}
                    handleElevenLabsVoiceSelect={this.handleElevenLabsVoiceSelect}
                    onAddVoice={this.onAddVoice}
                    background={mazeBackground}
                    mazeAvatar={mazeAvatar}
                    isBackgroundLoading={isBackgroundLoading}
                    audio={mazeVoice}
                    voice_type={voice_type || mazeVoiceType}
                    voice_accent={voice_accent || mazeVoiceAccent}
                    voice_name={voice_name || mazeSelectedVoice}
                    mazeAvatarId={avatar_id || mazeAvatarId}
                    backgroundError={mazeBackgroundError}
                    onAvatarChange={this.handleAvatarChange}
                  />
                </Col>
              )}
            </div>
            <div className="create-course">
              <BottomSection
                {...this.props}
                isMarketPlace={isMarketPlace}
                onOutline={this.handleOutline}
                onObjectives={this.handleObjectives}
                onDescription={this.handleDescription}
                onShareProfile={this.handleProfile}
                onMarketPlaceDescription={this.handleMarketPlaceDescription}
              />
            </div>
          </Form>
        </section>

        <div className="p-footer">
          <div className="p-footer__inner">
            <div className="p-footer__item">
              <Button
                className="rounded"
                onClick={this.handleSubmit}
                loading={updatingMaze}
              >
                {t('buttons:save_for_later')}{' '}
              </Button>
            </div>
            <div className="p-footer__item">
              <Button
                type="primary"
                className="rounded"
                onClick={this.handleNext}
                disabled={!mazeId || updatingMaze}
              >
                {t('buttons:next')}
                <Icon type="right" />
              </Button>
            </div>
          </div>
        </div>
        {image && (
          <EditImageContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}

        {openCreateVoiceModal && (
          <Modal
            visible={openCreateVoiceModal}
            onCancel={this.hideVoiceModal}
            title={audioModalTitle}
            className="custom-ant-modal"
            footer={null}
            width={'28%'}
          >
            <Select
              size="large"
              value={selectedLanguage}
              placeholder="Select a language"
              onChange={val => this.handleLanguageSelect(val, false)}
              style={{ width: '300px', marginTop: '20px' }}
            >
              {languages &&
                languages.sort().map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
            </Select>
            <Select
              size="large"
              value={mazeVoice || undefined}
              //loading={isGettingVoice}
              placeholder="Select a voice"
              style={{ width: '300px', margin: '20px 0' }}
              onChange={this.handleVoiceSelect}
              //disabled={!selectedLanguage || isGettingVoice}
              disabled={!selectedLanguage}
            >
              {filteredVoices &&
                filteredVoices.map((item, index) => (
                  <Select.Option key={index} value={item.voiceType}>
                    {`${item.voiceType[0].toUpperCase()}${item.voiceType.slice(
                      1
                    )}`}
                  </Select.Option>
                ))}
            </Select>
            {audioSrc && !_.isEmpty(audioSrc) && (
              <audio
                ref={this.audioRef}
                controls
                autoPlay="false"
                controlsList="nodownload"
                preload="none"
              >
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            )}
            <Button
              shape="round"
              type="primary"
              className="done-button"
              onClick={() => {
                this.hideVoiceModal()
                this.handleSubmit()
              }}
            >
              {' '}
              Verify Selection{' '}
            </Button>
          </Modal>
        )}

        {(openImagesModal || modalOpen) && (
          <Modal
            visible={openImagesModal || modalOpen}
            onCancel={this.hideModal}
            footer={null}
            width={'80%'}
            style={modal_style}
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                isPageBuilder
                //marketplaceOption={mazeMarketplaceOption}
                isMazeCreate={isBackgroundType}
                handleInsertData={this.handleInsertData}
                types="images"
              />
            </div>
            {selectedItem && (
              <Modal
                visible={selectedItem}
                width="70%"
                onCancel={this.hidePreviewModal}
                footer={null}
              >
                <Editor library={selectedItem} ref={this.imageRef} />

                <div className="wrap_btn_actions">
                  <Button
                    type="danger"
                    size="large"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {general.CANCEL}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="action_button"
                    onClick={() => this.handleFileInsert(selectedItem)}
                  >
                    {general.INSERT}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
        {openBadgeModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openBadgeModal}
            onCancel={this.toggleBadgeModal}
            footer={null}
            width="1229px"
            centered
          >
            <Badge
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Badge')
              }
              {...this.props}
            />
          </Modal>
        )}
        {openCertificateModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openCertificateModal}
            onCancel={this.toggleCertificateModal}
            footer={null}
            width="1229px"
            centered
          >
            <Certificate
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Certificate')
              }
              {...this.props}
            />
          </Modal>
        )}
      </Spin>
    )
  }
}

export default Form.create()(withTranslation('mazecreate')(MazeCreate))
